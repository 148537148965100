import React from 'react'
import FormDynamic from '../../components/FormDynamic'
import Main from '../../components/Main'
import { useLangContext } from '../../context/lang.context'

const content = {
  title: {
    pl: 'Skontaktuj się z ekspertami TSL',
    en: 'Contact TSL experts',
    ua: 'Зверніться до експертів TSL',
  },
  subtitle: {
    pl: 'Formularz kontaktowy',
    en: 'Contact form',
    ua: 'КОНТАКТНИЙ ФОРМУЛЯР',
  },
  desc: {
    pl: 'Wypełnij formularz kontaktowy. Nasz zespół skontaktuje się z Tobą i dopasuje rozwiązanie odpowiadające Twoim potrzebom.',
    en: 'Fill out the contact form. Our team will contact you and find a solution that suits your needs.',
    ua: 'Заповніть контактну форму. Наша команда звяжеться з вами та знайде рішення, яке відповідає вашим потребам.',
  },
}

const FormSectionMessage = () => {
  const { lang } = useLangContext()
  return (
    <Main
      h={2}
      s={1}
      title={content.title[lang]}
      desc={content.desc[lang]}
      subtitle={content.subtitle[lang]}>
      <FormDynamic notypes override='MESSAGE' />
    </Main>
  )
}

export default FormSectionMessage
