import React from 'react'

const Icon = (props) => (
  <svg
    css={{
      position: 'absolute',
      top: '0%',
      left: '50%',
      width: 32,
      height: 32,
      transform: 'translate(-50%, -100%)',
      filter: 'drop-shadow(0px 0px 30px white)',
    }}
    {...props}
    xmlns='http://www.w3.org/2000/svg'
    width='511.997'
    height='512'
    viewBox='0 0 511.997 512'>
    <g id='LOGO' transform='translate(2749.793 268.33)'>
      <path
        id='Path_37'
        dataname='Path 37'
        d='M1458.377,290.751V547.48a127.587,127.587,0,0,1-127.819,127.69H1074a127.549,127.549,0,0,1-88.235-35.456l-3.8-3.8c-1.24-1.3-2.434-2.592-3.617-4a117.661,117.661,0,0,1-10.811-13.659c-.814-1.188-1.623-2.43-2.378-3.672l-.6-.918c-.92-1.565-1.839-3.131-2.7-4.751a127.4,127.4,0,0,1-7.514-16.2c-.705-1.781-1.3-3.509-1.889-5.4s-1.187-3.671-1.734-5.4a144.563,144.563,0,0,1-3.187-14.524v-3.671a8.262,8.262,0,0,0-.375-2.807c-.386-.972-.327-3.455-.434-5.4a10.374,10.374,0,0,1,0-1.241h0V290.751A128.025,128.025,0,0,1,1074.493,163.17h256.556A128.2,128.2,0,0,1,1458.7,284.11h0v6.641Z'
        transform='translate(-3696.499 -431.5)'
        fill='#1a72ad'
        fillRule='evenodd'
        // stroke='white'
        // strokeWidth='60'
        // strokeLinecap='round'
      />
      <path
        id='Path_38'
        dataname='Path 38'
        d='M1010.036,187.344a115.043,115.043,0,0,0-55.795,20.147A9.9,9.9,0,0,0,950.57,211v2.486a30.408,30.408,0,0,0,4.211,5.4c2.862,3.457,5.4,7.075,8.371,10.8,27.385,36.782,54.389,72.646,79.181,111.427,18.688,29.274,38.291,65.409,68.706,83.718,33.647,20.254,88.9,20.957,124.6,5.887a119.876,119.876,0,0,0,44.238-31.813c1.294-1.4,15.665-18.094,5.4-15.232s-16.2,8.75-29,10.047c-49.695,5.023-67.033-22.47-87.5-62.222-16.209-31.273-34.3-69.459-54.607-97.816A115.6,115.6,0,0,0,1010.2,187.669Z'
        transform='translate(-3679.496 -326.197)'
        fill='#fff'
        fillRule='evenodd'
      />
      <path
        id='Path_39'
        dataname='Path 39'
        d='M1118.178,200.336c-25.387,1.35-44.723,15.068-62.115,32.407-15.339,15.231-31.113,32.732-46.015,48.611-8.81,9.291-20.958,13.4-9.943,25.547a68.236,68.236,0,0,0,27.385,20.2c26.737,8.479,49.043,1.135,71.566-13.449,12.588-8.156,30.625-37.809,43.747-50.826a62.144,62.144,0,0,1,29.87-17.337,49.814,49.814,0,0,1,36.734,7.615c1.13.918,10.367,10.37,11.446,7.076s-4.645-12.423-6.588-15.448c-21.119-33.217-57.521-46.4-96.087-44.452Z'
        transform='translate(-3480.461 -269.086)'
        fill='#fff'
        fillRule='evenodd'
      />
    </g>
  </svg>
)

export default Icon
